.ambassador-tile {
    background-color: $white;

    .ambassador-tile-text {
        .ambassador-tile-title {
            text-align: center;

            @include font-size(18px);
            @include my(3);
        }
    }

    .ambassador-tile-image-wrapper {
        position: relative;
    }

    .ambassador-tile-overlay {
        background-color: rgba($black, 0.6);
        bottom: 0;
        color: $white;
        display: flex;
        left: 0;
        opacity: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition: opacity 0.2s linear;

        @include font-size(16px);
        @include p(3);
    }

    &:hover {
        .ambassador-tile-overlay {
            opacity: 1;
        }
    }
}

;@import "sass-embedded-legacy-load-done:2908";