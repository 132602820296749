*:focus {
    outline: none !important;
}

html,
body {
    height: 100%;
}

.checkout,
.page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow: hidden;
}

.site-footer {
    margin-top: auto;
}

a {
    display: inline-block;
}

button,
a {
    &.disabled {
        cursor: not-allowed;
    }
    &::disabled {
        cursor: not-allowed;
    }
}

.img-fluid {
    width: 100%;
}

.main-content {
    overflow-x: hidden;

    @include pb(5);
}

@include media-breakpoint-up(lg) {
    .service-cloud-agent {
        .main-content {
            padding-top: $preheader-height + $header-height + 40px;
        }
    }
    .main-content {
        padding-top: $preheader-height + $header-height;
    }
}

@include media-breakpoint-down(md) {
    .service-cloud-agent {
        .main-content {
            padding-top: 90px;
        }
    }
    .main-content {
        padding-top: 75px;
    }
}

a {
    transition: opacity 0.2s ease-out;

    &:hover {
        text-decoration: none;
    }
}

dl {
    margin: 0;
    overflow: hidden;
    padding: 0;
    width: 100%;
}
dt {
    clear: both;
    float: left;
    font-weight: $font-weight-bold;
    margin-right: 5px;
    padding: 0 0.3rem 0 0;
}
dd {
    float: left;
    margin-bottom: 0.25rem;
}

.section-header {
    text-align: center;

    @include mb(5);
}

.modal-background {
    background-color: rgba(0, 0, 0, 0.75);
    bottom: 0;
    content: "";
    display: block;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: opacity 0.2s, visibility 0ms linear 0.2s, z-index 0ms linear 0.2s;
    visibility: hidden;
    z-index: -1;

    &.open {
        opacity: 1;
        transition: opacity 0.2s, visibility 0ms;
        visibility: visible;
        z-index: 2;
    }
}

;@import "sass-embedded-legacy-load-done:2643";