.dropdown {
    .dropdown-menu {
        border: 0;
        border-radius: 0;
        box-shadow: $default-box-shadow;
        margin: 0;
    }

    .dropdown-item {
        color: $site-menu-item-text !important;
    }
}

;@import "sass-embedded-legacy-load-done:2740";