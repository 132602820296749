.ratings {
    color: $product-rating;

    @include font-size(11px);
    @include pb(2);

    .fa-stack {
        height: 1rem;
        line-height: 1rem;
        vertical-align: -0.24999999rem;
        width: 1rem;
    }

    .fa-star.fa-inverse {
        color: $product-rating-empty;
    }
}

;@import "sass-embedded-legacy-load-done:2840";