@import '../variables';
@import '~base/components/pricing';

.price {
  .strike-through {
    color: $strikethrough-text-color;
    text-decoration: line-through;

    @include mr(1);
  }
}

;@import "sass-embedded-legacy-load-done:2773";